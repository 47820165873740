<template>
    <div>
        <NewBanner class="stickyNew"/>
        <SendReceiptModal :closeModal="() => toggleSendReceiptModal({ status: false, reference: null })" />
        <app-wrapper-alt>
            <template v-slot:child>
                <!--point of sale-->
                <div class="w-90 center">
                    <div class="flex flex-wrap items-center justify-between items-center pb2 pv4">
                        <div>
                            <div class="pb3 pb0-l f3 b">Sales History</div>
                        </div>
                        <!-- <button class="btn2">Export</button> -->
                    </div>
                </div>
                <!--point of sale-->

                <div class="w-90 center mb3">
                    <div
                        class="pv3 flex flex-wrap justify-between items-center"
                        style="border-top: 1px solid #e2e8ee; border-bottom: 1px solid #e2e8ee;"
                    >
                        <div class="flex justify-between w-20">
                            <SearchInput
                                v-if="role"
                                placeholder="Search sales..."
                                v-model="state.searchVal"
                                 @update:modelValue="search"
                            />
                        </div>
                        <div class="flex flex-wrap justify-end items-center w-80-l w-100 pt0-l pt3">
                            <input
                                @change="onFilterChange"
                                v-model="state.startDate"
                                type="date"
                                name="start"
                                class="input-style card-box mb3 mb0-l"
                                :max="todaysDate"
                            />
                            <input
                                @change="onFilterChange"
                                v-model="state.endDate"
                                type="date"
                                name="end"
                                class="input-style card-box mb3 mb0-l"
                                :max="todaysDate"
                            />
                            <select v-if="state.hasMultiBusiness"
                                @change="onFilterChange"
                                v-model="state.business"
                                name="business"
                                id="business"
                            >
                                <option value="all">All</option>
                                <option v-for="business in state.businesses" :key="business._id" :value="business.org">
                                    {{ business.name }}
                                </option>
                            </select>
                            <select 
                                @change="onFilterChange"
                                v-model="state.staff" 
                                :disabled="role !== 'admin' && role !== 'owner' && !rolePermissions.includes(100)"
                            >
                                <option value="">All</option>
                                <option v-for="(staff, index) in staffList" :key="'staff' + index">
                                    {{ staff.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="scrollmenu h-100 w-90 center">
                    <table class="w-100 tl mt3" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="pb3 pr3 box-border-bottom tl">Reference</th>
                                <th class="pb3 pr3 box-border-bottom tl">
                                    <div class="flex items-center">
                                        <span>Receipt</span>
                                        <span class="header-filter-sort-icon">
                                            <span v-if="state.isOpen && getTheValue == 'dateIssued'" class="expand sort1"
                                                @click="sortTransactionsTable('asc', 'dateIssued')">
                                                <img :src="require('@/assets/images/sort-up.png')"
                                                    class="directional-up" style="height:8px; width:7px"
                                                    alt="product" />
                                            </span>
                                            <span v-else class="expand sort1"
                                                @click="sortTransactionsTable('desc', 'dateIssued')">
                                                <img :src="require('@/assets/images/caret-down.png')"
                                                    class="directional-up" style="height:8px; width:7px"
                                                    alt="product" />
                                            </span>
                                        </span>
                                    </div>
                                </th>
                                <th class="pb3 pr3 box-border-bottom tl">Customer Name</th>
                                <th class="pb3 box-border-bottom tl">
                                    <div class="flex items-center">
                                        <span>Sold by</span>
                                        <span class="header-filter-sort-icon">
                                            <span v-if="state.isOpen && getTheValue == 'staffName'" class="expand sort1"
                                                @click="sortTransactionsTable('asc', 'staffName')">
                                                <img :src="require('@/assets/images/sort-up.png')"
                                                    class="directional-up" style="height:8px; width:7px"
                                                    alt="product" />
                                            </span>
                                            <span v-else class="expand sort1"
                                                @click="sortTransactionsTable('desc', 'staffName')">
                                                <img :src="require('@/assets/images/caret-down.png')"
                                                    class="directional-up" style="height:8px; width:7px"
                                                    alt="product" />
                                            </span>
                                        </span>
                                    </div>
                                </th>
                                <th class="pb3 box-border-bottom tl">Description</th>
                                <th class="pb3 box-border-bottom tl">
                                    <div class="flex items-center">
                                        <span>Sale Total</span>
                                        <span class="header-filter-sort-icon">
                                            <span v-if="state.isOpen && getTheValue == 'totalAmount'" class="expand sort1"
                                                @click="sortTransactionsTable('asc', 'totalAmount')">
                                                <img :src="require('@/assets/images/sort-up.png')"
                                                    class="directional-up" style="height:8px; width:7px"
                                                    alt="product" />
                                            </span>
                                            <span v-else class="expand sort1"
                                                @click="sortTransactionsTable('desc', 'totalAmount')">
                                                <img :src="require('@/assets/images/caret-down.png')"
                                                    class="directional-up" style="height:8px; width:7px"
                                                    alt="product" />
                                            </span>
                                        </span>
                                    </div>
                                </th>
                                <th class="pb3 box-border-bottom tl">
                                    <div class="flex items-center">
                                        <span>Status</span>
                                        <span class="header-filter-sort-icon">
                                            <span v-if="state.isOpen && getTheValue == 'status'" class="expand sort1"
                                                @click="sortTransactionsTable('asc', 'status')">
                                                <img :src="require('@/assets/images/sort-up.png')"
                                                    class="directional-up" style="height:8px; width:7px"
                                                    alt="product" />
                                            </span>
                                            <span v-else class="expand sort1"
                                                @click="sortTransactionsTable('desc', 'status')">
                                                <img :src="require('@/assets/images/caret-down.png')"
                                                    class="directional-up" style="height:8px; width:7px"
                                                    alt="product" />
                                            </span>
                                        </span>
                                    </div>
                                </th>
                                <th class="pb3 pr3 pr0-l box-border-bottom tl">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="salesHistory?.data?.length">
                            <tr v-for="(sale, index) in salesHistory.data" class="font-w1" :key="index">
                                <td class="pv3 pr4 pr3-l box-border-bottom tl">{{ sale?.number || sale.reference }}</td>
                                <td class="pv3 pr2 box-border-bottom tl b">
                                    <router-link v-if="sale?.reference && sale.status === 'completed'" :to="{ name: 'Transaction', params: { id: sale?.reference }, query: { business: sale?.org } }">
                                        <span>{{ sale?.items?.length }} items</span><br />
                                        <span>{{ formatDate(sale.dateIssued) }}</span>
                                    </router-link>
                                    <router-link v-else :to="{ name: 'PosReceipt', params: { id: sale?._id }, query: { business: sale.org } }">
                                        <span>{{ sale?.items?.length }} items</span><br />
                                        <span>{{ formatDate(sale.dateIssued) }}</span>
                                    </router-link>
                                </td>
                                <td class="pv3 pr4 pr3-l box-border-bottom tl">
                                    <div v-if="sale?.customer?.id && sale?.customer?.name !== ''" class="flex flex-wrap items-center">
                                        <div class="org-img-placeholder">
                                            <span>{{ getEntityInitials(sale.customer?.name || '') }}</span>
                                        </div>
                                        <div class="pl1 tooltip">
                                            <div>
                                                {{
                                                    sale.customer?.name?.length > 14
                                                        ? `${sale.customer.name.slice(0, 13)}...`
                                                        : sale.customer.name
                                                }}
                                                <span
                                                    v-if="sale.customer?.name?.length > 14"
                                                    class="tooltiptext tweakedtooltiptext"
                                                    style="padding: 10px;"
                                                    >{{ sale.customer?.name || '' }}</span
                                                >
                                            </div>
                                            <div>{{ sale.customer?.phone || '' }}</div>
                                        </div>
                                    </div>
                                    <div v-else class="flex flex-wrap justify-center items-center">-</div>
                                </td>
                                <td class="pv3 pr4 pr3-l box-border-bottom tl">
                                    <div class="flex flex-wrap items-center">
                                        <div class="pl1 tooltip">
                                            <div>
                                                {{
                                                    sale.staffName?.length > 14
                                                        ? `${sale.staffName?.slice(0, 13)}...`
                                                        : sale.staffName
                                                }}
                                            </div>
                                            <span
                                                v-if="sale.staffName?.length > 14"
                                                class="tooltiptext tweakedtooltiptext"
                                                style="padding: 10px;"
                                                >{{ sale.staffName }}</span
                                            >
                                            <div class="b" :title="sale.orgName">{{ 
                                                sale.orgName?.length > 14
                                                        ? `${sale.orgName?.slice(0, 13)}...`
                                                        : sale.orgName 
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="pv3 pr4 box-border-bottom tl">
                                    <span v-if="sale?.items?.length > 1">
                                        {{ sale?.items?.[0]?.name || sale?.items?.[0]?.productName }} +{{
                                        sale?.items.length - 1
                                        }}
                                        more...
                                    </span>
                                    <span v-else-if="sale?.items?.[0]?.name">
                                        {{
                                        sale?.items?.[0]?.name.length > 20
                                        ? `${sale?.items?.[0]?.name?.slice(0, 20)}...`
                                        : sale?.items?.[0]?.name || '-'
                                        }}
                                    </span>
                                    <span v-else>-</span>
                                </td>
                                <td class="pv3 pr4 box-border-bottom tl">
                                    {{ formatAmount(sale.totalAmount, $store.state.Settings?.currency) }}
                                </td>
                                <td class="pv3 box-border-bottom tl">
                                    <span
                                        v-if="sale.status === 'completed'"
                                        class="br-pill ph2 pv1"
                                        style="background: #EEFBF4; color: #219653;"
                                        >Completed</span
                                    >
                                    <span
                                        v-else-if="sale.status === 'parked'"
                                        class="br-pill ph2 pv1"
                                        style="background: #FDEDED; color: #EB5757;"
                                        >Parked</span
                                    >
                                </td>
                                <td class="pb3 box-border-bottom tc">
                                    <div class="dropdown">
                                        <a
                                            class="dropdown-togglex "
                                            href="#"
                                            role="button"
                                            id="dropdownMenuLink"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" class="fa-2x" />
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <li v-if="sale.status === 'parked'">
                                                <a href="" @click.prevent="retrieveSale(sale.reference)">
                                                    Retrieve
                                                </a>
                                            </li>
                                            <li v-if="sale.status === 'parked' && (role === 'owner' || role === 'admin' || rolePermissions?.includes(38))">
                                                <a href="" @click.prevent="deleteParkedSale(sale._id)">
                                                    Delete
                                                </a>
                                            </li>
                                            <li v-if="sale?.reference && sale.status === 'completed'">
                                                <router-link :to="{ name: 'Transaction', params: { id: sale?.reference } }">
                                                    View
                                                </router-link>
                                            </li>
                                            <li v-else>
                                                <router-link :to="{ name: 'PosReceipt', params: { id: sale?._id } }">
                                                    View
                                                </router-link>
                                            </li>
                                            <li v-if="sale.status === 'completed'">
                                                <router-link to="#" @click.prevent="resendReceipt(sale?.reference)">
                                                    Resend Receipt
                                                </router-link>
                                            </li>
                                            <li v-if="sale.status === 'completed'">
                                                <router-link to="#"
                                                    @click.prevent="toggleSendReceiptModal({ status: true, reference: sale?.reference })">
                                                    Send to new Email
                                                </router-link>
                                            </li>
                                            <li v-if="sale?.reference && sale.status === 'completed'">
                                                <router-link :to="{ name: 'Transaction', params: { id: sale?.reference }, query: {print: 'yes'} }">
                                                    Print
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="8">
                                    <TableEmptyState 
                                        :data-array="salesHistory?.data || []" 
                                        message="No POS sales record" 
                                        rout-name="Pos" 
                                        route-text="+ Record sale" 
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="salesHistory?.data?.length" class="scrollmenu w-90 center pt2">
                    <Page
                        v-model="salesHistory.page"
                        :total-row="salesHistory?.total"
                        :page-size-menu="[20,50,100]"
                        @change="onNavigate"
                        language="en"
                    />
                </div>
            </template>
        </app-wrapper-alt>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { formatAmount, formatDate, formatDateFilter, getEntityInitials } from '@/utils/lib'
import AppWrapperAlt from '@/layout/AppWrapperAlt'
import TableEmptyState from '@/components/TableEmptyState'
import NewBanner from '@/components/NewBanner'
import SendReceiptModal from '@/views/Transactions/widgets/SendReceiptModal'
import Swal from 'sweetalert2'
import SearchInput from 'vue-search-input'
import { Page } from 'v-page'

export default {
    name: 'SalesHistory',
    components: {
        AppWrapperAlt,
        TableEmptyState,
        NewBanner,
        SendReceiptModal,
        SearchInput,
        Page,
    },

    setup(props, { emit }) {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const getTheValue = ref('')
        const orgData = computed(() => store?.state?.Auth?.userData)
        const staffList = computed(() => store.state.Settings.members)
        const salesHistory = computed(() => store.state?.Pos?.sales)
        const role = computed(() => store?.state?.Auth?.role)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)


        console.log('sales history', salesHistory)

        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth()
        // const day = today.getDate()
        const todaysDate = formatDateFilter(new Date())

        const state = reactive({
            isOpen: false,
            hasMultiBusiness: false,
            business: 'all',
            staff: '',
            businesses: '',
            startDate: formatDateFilter(new Date(year, month, 1)),
            endDate: formatDateFilter(new Date()),
            searchVal: ''
        })

        const retrieveSale = reference => {
            store
                .dispatch('Pos/retrieveSale', reference)
                .then(data => {
                    emit('onRetrieveSales', data)
                    router.push({ name: 'Pos', query: {parked: reference} })
                })
                .catch(error => {
                    console.error(error)
                })
        }

        const deleteParkedSale = id => {
            Swal.fire({
                title: `Are you sure you want to delete this parked sale?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, Continue',
                denyButtonText: `No, Cancel`
            }).then(result => {
                if (result.isConfirmed) {
                    store.dispatch('Pos/deleteParkedSale', id).finally(() => {
                        const query = {
                            filterType: route.query?.filterType || 'customrange',
                            filterByBusiness: state.business,
                            startDate: state.startDate,
                            endDate: state.endDate
                        }
                        store.dispatch('Pos/getSalesHistory', query)
                    })
                }
            })
        }

        const search = () => {
            const query = {
                filterType: 'search',
                q: state.searchVal,
                filterByBusiness: state.business,
                filterByStaff: state.staff,
                startDate: state.startDate,
                endDate: state.endDate
            }
            
            store.dispatch('Pos/getSalesHistory', query)
        }

        const onFilterChange = async () => {
            const query = {
                filterType: route.query?.filterType || 'customrange',
                filterByBusiness: state.business,
                filterByStaff: state.staff,
                startDate: state.startDate,
                endDate: state.endDate
            }
            
            store.dispatch('Pos/getSalesHistory', query)
        }

        const sortTransactionsTable = (mode, sortBy) => {
            state.isOpen = !state.isOpen
            getTheValue.value = sortBy
            const query = {
                filterByBusiness: state.business,
                filterByStaff: state.staff,
                startDate: state.startDate,
                endDate: state.endDate,
                filterType: route.query?.filterType || 'customrange',
                limit: route.query?.limit || 25,
                page: route.query?.page || 1,
                sort: route.query?.sort || sortBy,
                mode: route.query?.mode || mode,
            }

            store.dispatch('Pos/getSalesHistory', query)
        }

        const resendReceipt = (reference) => {
            store.dispatch('Transaction/resendReceipt', reference)
        }

        const toggleSendReceiptModal = (payload) => {
            store.dispatch('Transaction/toggleSendReceiptModal', { ...payload })
        }

        const getSalesHistory = (query=null) => {
            const filter = {
                filterByBusiness: 'all',
                startDate: formatDateFilter(new Date(year, month, 1)),
                endDate: formatDateFilter(new Date()),
                filterType: route.query?.filterType || 'customrange',
                limit: route.query?.limit || 25,
                page: route.query?.page || 1,
                sort: route.query?.sort || 'createdAt',
                mode: route.query?.mode || 'desc',
            }
            store.dispatch('Pos/getSalesHistory', filter)
        }

        const onNavigate = (query) => {
            getSalesHistory(query);
        }

        onMounted(() => {
            store.dispatch('Settings/getTeamMembers')
            store.dispatch('Settings/getBusinesses', true).then(resp => {
                if (resp.status) {
                        state.hasMultiBusiness = resp.data.length > 1
                        state.businesses = resp.data
                        state.business = orgData.value.orgId
                    }
                })
            getSalesHistory();
        })

        return {
            state,
            salesHistory,
            staffList,
            retrieveSale,
            deleteParkedSale,
            formatAmount,
            formatDate,
            getEntityInitials,
            search,
            onFilterChange,
            getTheValue,
            sortTransactionsTable,
            todaysDate,
            role,
            rolePermissions,
            resendReceipt,
            toggleSendReceiptModal,
            onNavigate,
        }
    }
}
</script>
<style scoped>
.searchContainer2 {
    overflow: unset;
}
.org-img-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(215, 222, 250);
    height: 25px;
    width: 25px;
    border-radius: 50%;
    font-size: 25px;
    color: rgb(19, 44, 140);
    margin-right: 5px;
}
.org-img-placeholder span {
    font-size: 11px;
}

.tweakedtooltiptext {
    margin-left: -30px;
    width: 300px;
}
.stickyNew {
    position: sticky;
    top: 0;
    z-index: 53;
}
</style>
